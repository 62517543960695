.evaluations {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 0;
    justify-content: center;
}

.evaluations-page .evaluations-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.evaluations-page .evaluations-group-header .left-header-info:hover {
    opacity: 0.7;
    cursor: pointer;
}

.evaluations-page .evaluations-group-header .manager-title {
    font-weight: var(--font-weight-bold);
    color: var(--secondary-color);
}

.evaluations-page .evaluations-group-header .delegated-to {
    color: var(--placeholder-disabled-color);
    font-weight: var(--font-weight-bold);
}

.evaluations-page .evaluations-group-header .right-header-info:hover {
    opacity: 1;
}

.evaluations-page .evaluations-group-header .right-header-info {
    display: flex;
    gap: 1rem;
}

.evaluations table thead {
    vertical-align: bottom;
}

.evaluations table .table-column-header {
    font-size: 0.8571rem;
    padding: 0.4286rem 1.2rem 0.2rem .5rem;
    max-height: 8.5714rem;
}

.evaluations .evaluations-table .table-row {
    background-color: var(--grey-palette-02);
}

.evaluations .evaluations-table .table-row:nth-child(odd) {
    background-color: var(--grey-palette-03);
}

.evaluations .evaluations-table .table-row.evaluation-rejected {
    background-color: rgba(234, 124, 132, 0.3);
}

.evaluations .evaluations-table .table-row:nth-child(odd).evaluation-rejected {
    background-color: rgba(234, 124, 132, 0.4);
}

.evaluations table .table-row .score {
    display: flex;
    flex-direction: column;
    gap: .2857rem;
}

.evaluations .evaluations-table .problematic-absence {
    max-width: 1.7143rem;
    max-height: 1.7143rem;
}

.evaluations .table-row .locked-value {
    padding-left: 1rem;
}

.evaluations .evaluations-table .table-row .table-cell {
    padding: .7143rem .5714rem;
    font-size: 0.8571rem;
}

.evaluations .evaluations-table .employee-info {
    display: flex;
    flex-direction: column;
    gap: .0714rem;
    max-width: 10rem;
}

.evaluations .evaluations-table .employee-info .employee-id {
    color: var(--grey-palette-01);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.evaluations .evaluations-table .presence-cell {
    display: flex;
    align-items: center;
    gap: 2px
}

.evaluations .evaluations-table .form-field {
    flex: 1;
}

.evaluations .evaluations-table .text-input {
    min-width: unset;
    width: 100%;
}

.evaluations .evaluations-table .small-input {
    min-width: unset;
    width: 5.7143rem;
}

.evaluations .evaluations-table .text-input .native-input {
    overflow: hidden;
}

.evaluations .evaluations-table .red-bold-text {
    font-weight: var(--font-weight-bold);
    color: var(--status-red);
}

.dirty-calculations {
    opacity: 0.3
}


.round-icon-button.validate-btn-no-action:hover {
    pointer-events: none;
}


.round-icon-button.confirmed-btn {
    background: var(--status-blue);
}

.round-icon-button.confirmed-btn-no-action:hover {
    background: var(--status-blue);
    pointer-events: none;
}


.round-icon-button.calibrated-btn {
    pointer-events: none;
    background: var(--gradient-main);
}


.bonus-container {
    display: flex;
    gap: .8571rem;
    font-weight: var(--font-weight-regular);
    color: var(--secondary-color);
}

.bonus-cut {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: var(--status-red);
    color: #ffffff;
    cursor: default;
    font-weight: var(--font-weight-bold);
}

.cut-bonus-container,
.evaluations-table .rejected-reason {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp, 4);
    -webkit-box-orient: vertical;
    word-break: var(--word-break, 'none');
    overflow: hidden;
    -webkit-hyphens: auto;
    hyphens: auto;
    cursor: pointer;
}

.cut-bonus-container:hover,
.evaluations-table .rejected-reason:hover {
    opacity: .5;
}

.evaluations-table .feedback-submitted svg path {
    fill: var(--status-blue);
}

.evaluations-table .feedback-given-inperson svg path{
    fill: var(--primary-color);
}

.popup-rejected-reason .rejection {
    display: flex;
    gap: .2857rem
}

/* @media only screen and (max-width: 768px) {
    .evaluations .filters-container {
        justify-content: unset;
        flex-wrap: wrap;
    }

    .evaluations .filters-container .checkbox-info {
        display: flex;
        gap: 1.1429rem;
    }
}

@media only screen and (max-width: 425px) {

    .evaluations .filters-container .form-field,
    .evaluations .filters-container .text-input.search-box {
        width: 100%;
    }
} */