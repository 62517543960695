.loader-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  gap: 1.1429rem;
}

.loader-container .loader {
  width: 3.5714rem;
  height: 3.5714rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2143rem;
}

.loader-container .loader > div {
  background-color: var(--grey-palette-01);
  height: 100%;
  width: 0.4286rem;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.loader-container .loader .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loader-container .loader .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.loader-container .loader .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loader-container .loader .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.loader-container .loader-text {
  color: var(--grey-palette-01);
}
