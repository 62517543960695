.mobile-table-cell {
  display: flex;
  flex-direction: column;
  gap: 0.1429rem;
}

.mobile-table-cell-label {
  font-weight: var(--font-weight-bold);
  color: var(--grey-palette-01);
}
