.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-container .checkbox,
.checkbox-container .checkbox.disabled,
.checkbox-container .checkbox.disabled:hover {
  min-width: 1.4286rem;
  min-height: 1.4286rem;
  background-color: #ffffff;
  border: 1px solid var(--secondary-color);
  border-radius: 3px;
  cursor: pointer;
}

.checkbox-container .label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.checkbox-container .label .additional-text{
  padding-left: .5714rem;
  color: var(--grey-palette-01);
  font-size: .8571rem;
}

.checkbox-container .label .additional-text.red{
  font-weight: bold;
  color: var(--error-color);
}


.checkbox-container .checkbox:hover {
  border: 1px solid var(--success-color);
}

.checkbox-container .checkbox.checked {
  background-color: var(--success-color);
}

.checkbox-container .checkbox.disabled,
.checkbox-container .checkbox.disabled:hover {
  opacity: 0.3;
  cursor: unset;
}

.checkbox-container .label.disabled {
  opacity: 0.3;
}
