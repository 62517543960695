.page-layout.cut-reasons-page .page-content {
  display: flex;
  flex-direction: column;
}

.page-layout.cut-reasons-page .page-content .text-input.search-box {
  width: 19.7857rem;
  margin-bottom: .8571rem;
}

.page-layout.cut-reasons-page .page-content .button.create-test-btn {
  min-width: 9.5rem;
}

.page-layout.cut-reasons-page .page-content .cut-factors {
  display: flex;
  flex-direction: column;
  gap: .1429rem;
}

.page-layout.cut-reasons-page .page-content .cut-factors .company-factor,
.page-layout.cut-reasons-page .page-content .cut-factors .employee-factor {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.popup-container.system-popup.warning-popup.disable-cut-reason-popup .popup-buttons .button.primary {
  background: var(--status-red);
}

.page-layout.cut-reasons-page .page-content .name {
  display: flex;
  flex-direction: column;
  gap: .1429rem;
}

.page-layout.cut-reasons-page .page-content .name .pt-pt,
.page-layout.cut-reasons-page .page-content .name .en-en{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .2857rem;
}

.page-layout.cut-reasons-page .page-content .name .pt-pt svg,
.page-layout.cut-reasons-page .page-content .name .en-en svg {
  width: 1.4286rem;
  height: 1.4286rem;
}

@media only screen and (max-width: 425px) {
  .page-layout.cut-reasons-page .page-content .text-input.search-box {
      width: 100%;
  }
}
