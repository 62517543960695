.splash-screen {
  background: var(--grey-palette-02);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.splash-screen .splash-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.1429rem;
}

.splash-screen .splash-logo svg {
  width: 14.2857rem;
  height: auto;
}
