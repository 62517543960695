.side-bar .icon {
  width: 2.8571rem;
  height: 2.8571rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.side-bar .icon.bottom-aligned {
  margin-top: auto;
}

.side-bar .icon a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-bar .icon a svg {
  width: 100%;
}

.side-bar .icon:hover a svg path {
  fill: var(--primary-color);
}

.side-bar .icon a.selected svg path {
  fill: var(--primary-color);
}
