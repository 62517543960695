.sub-side-bar {
  height: 100%;
  /* min-width: 4.2857rem;
  max-width: 4.2857rem; */
  min-width: 5rem;
  max-width: 5rem;
  background-color: var(--grey-palette-03);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5714rem 1.0714rem;
  gap: 1.4286rem;
}

/* .sub-side-bar:hover {
  min-width: fit-content;
  max-width: 12.8571rem;
} */

.sub-side-bar .sub-side-bar-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.5px;
}

.sub-side-bar .sub-side-bar-user .avatar-container {
  cursor: default;
}

.sub-side-bar .sub-side-bar-user .user-avatar {
  width: 2.1429rem;
  height: 2.1429rem;
  background: var(--secondary-color);
  font-size: .9286rem;
  cursor: default;
}

/* .sub-side-bar:hover .sub-side-bar-user .user-avatar {
  width: 3.4286rem;
  height: 3.4286rem;
  font-size: 1.4286rem;
} */

.sub-side-bar .sub-side-bar-user .user-info {
  display: flex;
  flex-direction: column;
  gap: .1429rem;
  text-align: center;
}

/* .sub-side-bar:hover .sub-side-bar-user .user-info {
  display: flex;
} */

.sub-side-bar .sub-side-bar-user .user-info .user-name {
  font-size: .7857rem;
  font-weight: var(--font-weight-bold);
}

.sub-side-bar .sub-side-bar-user .user-info .user-job-title {
  color: var(--grey-palette-01);
}

.sub-side-bar .sub-side-bar-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.1429rem;
}

.sub-side-bar .sub-side-bar-btns {
  align-items: flex-start;
}
/* 
.sub-side-bar:hover .sub-side-bar-btns .sub-side-bar-btn a {
  padding-right: 1.0714rem;
}

.sub-side-bar:hover .sub-side-bar-btns .sub-side-bar-btn .label {
  display: block;
} */

.sub-side-bar .sub-side-bar-btns {  /* TEMP */
  display: flex;
  align-items: center;
}
