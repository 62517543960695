.mobile-table-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mobile-table-footer .mobile-table-footer-page-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--grey-palette-01);
  gap: 0.4286rem;
  user-select: none;
}

.mobile-table-footer
  .mobile-table-footer-page-items
  .mobile-table-footer-page-items-info {
  color: var(--grey-palette-01);
}

.mobile-table-footer .mobile-table-footer-buttons {
  display: flex;
  gap: 0.7143rem;
}
.mobile-table-footer .mobile-table-footer-buttons .mobile-table-footer-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.7857rem;
  width: 1.7857rem;
  border-radius: 0.2857rem;
  background-color: var(--secondary-color);
}

.mobile-table-footer
  .mobile-table-footer-buttons
  .mobile-table-footer-button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.mobile-table-footer
  .mobile-table-footer-buttons
  .mobile-table-footer-button
  svg {
  height: 1.14rem;
  width: 1.14rem;
}

.mobile-table-footer
  .mobile-table-footer-buttons
  .mobile-table-footer-button
  svg
  path {
  fill: #ffffff;
}

.mobile-table-footer
  .mobile-table-footer-buttons
  .mobile-table-footer-button.rotate {
  transform: rotate(180deg);
}

.mobile-table-footer
  .mobile-table-footer-buttons
  .mobile-table-footer-button.disable {
  opacity: 0.3;
  pointer-events: none;
}
