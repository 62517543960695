.round-icon-button {
    display: flex;
    height: 1.7143rem;
    width: 1.7143rem;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color);
    border-radius: 50%;
}

.round-icon-button:hover {
    opacity: .7;
    cursor: pointer;
}


.round-icon-button.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.round-icon-button svg {
    height: 1.1429rem;
    width: auto;
}