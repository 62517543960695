.criteria-section {
    display: flex;
    flex-direction: column;
    gap: .2857rem;
}

.criteria-section .criteria-section-header {
    display: flex;
    justify-content: space-between;
}

.criteria-section .table-row {
    background-color: var(--grey-palette-02);
}

.criteria-section .table-row:nth-child(odd) {
    background-color: var(--grey-palette-03);
}

.criteria-section .form-field {
    flex: 1;
}

.criteria-section .text-input {
    min-width: unset;
    width: 100%;
}

.criteria-section .small-input {
    min-width: unset;
    width: 100%;
    min-width: 4.2857rem;
}

.criteria-section .subtitle {
    display: flex;
    gap: .5714rem;
}

.criteria-section .subtitle-right-btns {
    display: flex;
    gap: .5714rem;
}

.criteria-section .text-input .native-input {
    overflow: hidden;
    width: 100%;
}

.company-factors .company-factors-subtotalAchievement {
    display: flex;
    justify-content: end;
}