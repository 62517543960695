
.table-cell{
  display: flex;
  padding: 0.93rem 0.857rem;
  align-items: center;
  min-height: 3.7143rem;
}

@media only screen and (max-width: 1200px) {
  .table-cell{
    padding: 0.93rem .5714rem;
  }
}