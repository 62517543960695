.toggle {
    display: flex;
    height: 1.4286rem;
    width: 2.5714rem;
    border-radius: 1.1429rem;
    background-color: var(--grey-palette-01);
    cursor: pointer;
}

.toggle:hover {
    opacity: 0.7;
}

.toggle.active {
    flex-direction: row;
    justify-content: end;
    background-color: var(--primary-color);
}

.toggle .slider {
    height: 1.1429rem;
    width: 1.1429rem;
    background-color: white;
    border-radius: 50%;
    margin: .1429rem;
}

.toggle.disabled {
    opacity: 0.3;
    cursor: unset;
}