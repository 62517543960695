.popup-register-comment .popup-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.popup-register-comment .employee-card {
    padding: 1rem;
}

.popup-register-comment .employee-card .employee-label {
    color: var(--grey-palette-01);
}

.popup-register-comment .employee-card .employee-value {
    font-weight: var(--font-weight-bold);
}

.popup-register-comment .comments {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
    gap: 1.1429rem;
}