.table-footer-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.857rem 0.857rem;
  padding-bottom:0;
  color: var(--grey-palette-01);
  gap: 1.1429rem;
  user-select: none;
}

.table-footer-pagination .table-footer-pagination-info {
  color: var(--grey-palette-01);
}

.table-footer-pagination .table-footer-pagination-buttons {
  display: flex;
  gap: 0.7143rem;
}

.table-footer-pagination .table-footer-pagination-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.7857rem;
  width: 1.7857rem;
  border-radius: 0.2857rem;
  background-color: var(--secondary-color);
}

.table-footer-pagination .table-footer-pagination-button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.table-footer-pagination .table-footer-pagination-button svg {
  height: 1.14rem;
  width: 1.14rem;
}

.table-footer-pagination .table-footer-pagination-button svg path {
  fill: #ffffff;
}

.table-footer-pagination .table-footer-pagination-button.rotate {
  transform: rotate(180deg);
}

.table-footer-pagination .table-footer-pagination-button.disable {
  opacity: 0.3;
  pointer-events: none;
}
