.popup-delegate-managers{
    min-width: 30vw;
}

.popup-delegate-managers .manager-card {
    display: flex;
    flex-direction: column; 
    gap: .5714rem;
    padding: 1rem;
}

.popup-delegate-managers .manager-card .manager-label {
    font-weight: var(--font-weight-bold);
    color: var(--grey-palette-01);
}

.popup-delegate-managers .manager-card .manager-value {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: var(--font-weight-bold);
}

.popup-delegate-managers .manager-card .manager-value svg {
    min-width: 1.7143rem;
    min-height: 1.7143rem;
}

.popup-delegate-managers .manager-card .manager-value span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.popup-delegate-managers .selected-manager-container {
    display: flex;
    flex-direction: column;
    gap: .5714rem;
    padding: 1rem;
    background-color: var(--secondary-color);
    color: #ffffff;
}

.popup-delegate-managers .selected-manager-container .manager-label {
    font-weight: var(--font-weight-bold);
}

.popup-delegate-managers .selected-manager-container .manager-value {
   display: flex;
   align-items: center;
   gap: .5714rem;
}

.popup-delegate-managers .employee-info {
    display: flex;
    flex-direction: column;
    gap: .0714rem;
    max-width: 8.5714rem;
}

.popup-delegate-managers .employee-email {
    color: var(--grey-palette-01);
/*     white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
}
