.evaluations-attachments-page .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.add-info-attachments .add-info-header svg path:first-child,
.add-info-attachments .add-info-btn svg path:first-child {
  fill: none;
}
