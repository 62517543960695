.breadcrumb {
  display: flex;
  list-style: none;
  color: var(--grey-palette-01);
  font-weight: bold;
  font-size: 0.857rem;
  padding-left: initial;
  margin: initial;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.breadcrumb-item a {
  text-decoration: none;
  color: var(--grey-palette-01);
}

.breadcrumb-item a:hover {
  text-decoration: underline;
  /* linha no hover */
}

.breadcrumb-item.selected a {
  color: var(--grey-palette-01);
  text-decoration: underline;
  /* fica ou nao?? */
}

.breadcrumb-separator {
  margin: 0 5px;
  color: var(--grey-palette-01);
}

.breadcrumb-item:last-child .breadcrumb-separator {
  display: none;
}