.mobile-sub-side-bar {
  position: fixed;
  bottom: 0;
  z-index: 2;
  height: 50px;
  width: 100vw;
  background-color: var(--grey-palette-03);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.1429rem;
}

.mobile-sub-side-bar .sub-side-bar-user .user-avatar {
  width: 2.1429rem;
  height: 2.1429rem;
  background: var(--secondary-color);
  font-size: 1rem;
}

.mobile-sub-side-bar .sub-side-bar-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.1429rem;
}
