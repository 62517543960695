.mobile-nav-btn {
  cursor: pointer;
  -webkit-transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25s ease-in-out;
  -o-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
  border-radius: 0.3125rem;
  text-decoration: none;
  color: var(--main-text);
  font-weight: 700;
}

.mobile-nav-btn:hover {
  background-color: var(--white-gray);
}

.mobile-nav-btn.selected {
  background-color: var(--white-gray);
}

.mobile-nav-btn .mobile-nav-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobile-nav-btn .mobile-nav-btn-container .mobile-nav-btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
}

.mobile-nav-btn .mobile-nav-btn-container .mobile-nav-btn-icon svg {
  width: 100%;
  height: auto;
}

.mobile-nav-btn .mobile-nav-btn-container .mobile-nav-btn-name {
  padding: 0 0.625rem;
}
