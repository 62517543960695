.session-expired-page {
  width: 100vw;
  height: 100vh;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.session-expired-page .wrapper {
  padding: 2rem;
}

.session-expired-page .wrapper .error-text .error {
  opacity: 0.5;
}

.session-expired-page .wrapper .error-text .error svg {
  width: 8.75rem;
  height: auto;
}

.session-expired-page .wrapper .error-text .info {
  padding: 1.625rem 0 2rem 0;
}

.session-expired-page .wrapper .error-text .info .title {
  line-height: normal;
  margin-bottom: 0.625rem;
  color: #ffffff;
}

.session-expired-page .wrapper .error-text .info .text {
  color: #ffffff;
}

.session-expired-page .wrapper .action-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.session-expired-page .wrapper .action-btns .session-expired-btn {
  min-width: 8.9375rem;
}

/*////////////////////////////////////////////////// MEDIA QUERIES //////////////////////////////////////////////////*/

@media only screen and (max-width: 768px) {
  .session-expired-page .wrapper .error-text .error svg {
    width: 5.75rem;
    height: auto;
  }

  .session-expired-page .wrapper .error-text .info .title {
    font-size: 1.375rem;
  }
}

@media only screen and (max-width: 320px) {
  .session-expired-page .wrapper .action-btns .session-expired-btn {
    width: 100%;
  }
}
