.unavailable-service {
  width: 100vw;
  height: 100vh;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.unavailable-service .wrapper {
  padding: 2rem;
  width: 50%;
}

.unavailable-service .error-text .error {
  opacity: 0.4;
}

.unavailable-service .error-text .error svg {
  width: 14.2857rem;
  height: auto;
}

.unavailable-service .error-text .error svg path {
  fill: #ffffff;
}

.unavailable-service .error-text .info {
  padding: 0 0 2rem 0;
}

.unavailable-service .error-text .info .title {
  line-height: 1.5;
  margin-bottom: 0.625rem;
  color: #ffffff;
}

.unavailable-service .error-text .info .text {
  color: #ffffff;
}

.unavailable-service .error-page-btn {
  display: inline-flex;
}

/*////////////////////////////////////////////////// MEDIA QUERIES //////////////////////////////////////////////////*/

@media only screen and (max-width: 1024px) {
  .unavailable-service .wrapper {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .unavailable-service .error-text .error {
    font-size: 7.5rem;
    line-height: 6rem;
  }

  .unavailable-service .error-text .info .title {
    font-size: 1.375rem;
  }
}

@media only screen and (max-width: 320px) {
  .unavailable-service .wrapper {
    width: 100%;
  }

  .unavailable-service .error-page-btn {
    width: 100%;
  }
}
