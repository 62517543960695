.error-page {
  width: 100vw;
  height: 100vh;
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.error-page .wrapper {
  padding: 2rem;
  width: 50%;
}

.error-page .error-text .error {
  font-size: 15rem;
  font-weight: var(--font-weight-bold);
  line-height: 11rem;
  opacity: 0.4;
}

.error-page .error-text .info {
  padding: 1.625rem 0 2rem 0;
}

.error-page .error-text .info .title {
  line-height: 1.5;
  margin-bottom: 0.625rem;
  color: #ffffff;
}

.error-page .error-text .info .text {
  color: #ffffff;
}

.error-page .error-page-btn {
  display: inline-flex;
}

/*////////////////////////////////////////////////// MEDIA QUERIES //////////////////////////////////////////////////*/

@media only screen and (max-width: 1024px) {
  .error-page .wrapper {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .error-page .error-text .error {
    font-size: 7.5rem;
    line-height: 6rem;
  }

  .error-page .error-text .info .title {
    font-size: 1.375rem;
  }
}

@media only screen and (max-width: 320px) {
  .error-page .wrapper {
    width: 100%;
  }

  .error-page .error-page-btn {
    width: 100%;
  }
}
