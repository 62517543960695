.absence-codes-page .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.absence-codes-page .page-content .text-input.search-box {
    width: 19.7857rem;
}

.popup-container.system-popup.warning-popup.disable-absence-code-popup .popup-buttons .button.primary {
    background: var(--status-red);
}

@media only screen and (max-width: 425px) {
    .absence-codes-page .page-content .text-input.search-box {
        width: 100%;
    }
}