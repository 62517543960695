.popup-cut-bonus .cut-bonus {
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
}

.popup-cut-bonus .cut-bonus .employee-card {
    padding: 1rem;
}

.popup-cut-bonus .cut-bonus .employee-card .employee-label {
    color: var(--grey-palette-01);
}

.popup-cut-bonus .cut-bonus .employee-card .employee-value {
    font-weight: var(--font-weight-bold);
}

.popup-cut-bonus .popup-buttons .cut-bonus-btn {
    background-color: var(--status-red);
}