.toggle-div {
  position: relative;
}

.toggle-div .toggle-div-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.popup-container.system-popup.warning-popup.disable-location-popup .popup-buttons .button.primary {
  background: var(--status-red);
}