.tooltip-host {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  /* display: flex;
    align-items: center;
    justify-content: center; */
}

.tooltip-host:hover .tooltip {
  animation: show 0.8s ease-in-out forwards;
  display: flex;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tooltip-host .tooltip {
  width: max-content;
  max-width: 21.4286rem;
  position: absolute;
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0.2857rem 0.5rem;
  background-color: #ffffff;
  box-shadow: 0px 0.375rem 0.9375rem 0.3125rem rgba(0, 0, 0, 0.1);
  border-radius: 0.2143rem;
  opacity: 0;

  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}