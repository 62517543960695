.page-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 2.8571rem 0 2.8571rem;
  gap: 1.1429rem;
  overflow: auto;
}

.page-layout .page-layout-header {
  display: flex;
  justify-content: space-between;
}

.page-layout .page-layout-header .page-layout-header-action-buttons {
  display: flex;
  align-items: center;
  gap: 1.1429rem;
}

.page-layout .page-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}


@media only screen and (max-width: 1024px) {
  .page-layout {
    padding: 1.4286rem;
  }
}

@media only screen and (max-width: 768px) {
  .page-layout .page-layout-header .page-layout-header-action-buttons {
    align-items: flex-start;
  }
}