.history {
    display: flex;
    gap: .7143rem;
}

.history .history-avatar {
    width: 2.1429rem;
    height: 2.1429rem;
    font-size: 1rem;
    background: var(--secondary-color);
}

.history .history-info {
    display: flex;
    flex-direction: column;
    gap: .2143rem;
    width: 100%;
}

.history .history-info .operated-by {
    font-weight: var(--font-weight-bold);
    color: var(--grey-palette-01);
}

.history .history-info .history-line-text {
    line-height: 1.2;
}

.history .history-info .separator {
    font-weight: var(--font-weight-bold);
    color: var(--grey-palette-01);
    border-bottom: 1px solid rgba(185, 184, 184, 0.2);
    padding-bottom: 0.125rem;
    margin-top: 0.2857rem;
}