.access-denied-page {
    width: 100vw;
    height: 100vh;
    background-color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
  
  .access-denied-page .wrapper {
    padding: 2rem;
    width: 50%;
  }

  .access-denied-page .access-denied-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .access-denied-page .access-denied-text .icon {
    height: 21.4286rem;
    width: 21.4286rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--gradient-main);
  }

  .access-denied-page .access-denied-text .icon svg{ 
    height: 20rem;
    width: 20rem;
  }
  
  .access-denied-page .access-denied-text .info {
    padding: 1.625rem 0 2rem 0;
  }
  
  .access-denied-page .access-denied-text .info .title {
    line-height: 1.5;
    margin-bottom: 0.625rem;
    color: #ffffff;
  }
  
  .access-denied-page .access-denied-text .info .text {
    color: #ffffff;
  }
  
  .access-denied-page .access-denied-btns  {
    display: inline-flex;
    gap: 1.1429rem;
  }
  
  /*////////////////////////////////////////////////// MEDIA QUERIES //////////////////////////////////////////////////*/
  
  @media only screen and (max-width: 1024px) {
    .access-denied-page .wrapper {
      width: 80%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .access-denied-page .access-denied-text .error {
      font-size: 7.5rem;
      line-height: 6rem;
    }
  
    .access-denied-page .access-denied-text .info .title {
      font-size: 1.375rem;
    }
  }
  
  @media only screen and (max-width: 320px) {
    .access-denied-page .wrapper {
      width: 100%;
    }
  
    .access-denied-page .access-denied-btns .back-home-btn {
      width: 100%;
    }
  }
  