.notification-container {
  position: fixed;
  bottom: 3.125rem;
  right: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  max-height: 100vh;
  max-width: 18.75rem;
  z-index: 12;
}
