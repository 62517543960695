.popup-import .import-btns {
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
}

.popup-import .import-btns .template-section, .popup-import .import-btns .import-section{
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
}

.popup-import .import-btns .button {
    width: 100%;
}


.popup-import .warning-import-message{
    height: auto;
    white-space: unset;
}

@media only screen and (max-width: 768px) {
    .popup-import .import-btns {
        flex-direction: column;
    }

    .popup-import .import-btns .button {
        width: 100%;
    }
}