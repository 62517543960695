.popup-container.system-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
/*   max-width: 25.571rem; */
}

.popup-container.system-popup.error-popup {
  width: auto;
  max-width: unset;
}

.popup-container.system-popup .popup-icon {
  display: flex;
}

.popup-container.system-popup .popup-header {
  font-weight: var(--font-weight-semibold);
  font-size: 1.286rem;
}

.popup-container.system-popup .popup-content {
  font-weight: var(--font-weight-regular);
  display: flex;
  flex-direction: column;
  text-align: center;
}
