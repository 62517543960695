.popup-container.create-period-popup {
    min-width: 40vw;
}

.popup-container.create-period-popup .popup-content .separator {
    font-weight: var(--font-weight-bold);
    color: var(--grey-palette-01);
    border-bottom: 1px solid rgba(185, 184, 184, 0.2);
    padding-bottom: 0.125rem;
    margin-top: 0.2857rem;
}

.popup-container.create-period-popup .popup-buttons {
    justify-content: flex-end;
}

.popup-container.create-period-popup .popup-buttons .button{
    flex: unset;
    min-width: 9.5rem;
}

@media only screen and (max-width: 768px) {
    .popup-container.create-period-popup {
        min-width: unset;
    }
  }

@media only screen and (max-width: 425px) {
    .popup-container.create-period-popup .popup-buttons .button{
        flex: 1;
        min-width: unset;
    }
  }