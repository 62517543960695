.text-area {
  display: flex;
  height: 8.14rem;
  min-width: 14.286rem;
  border: 1px solid var(--border-main-color);
  padding: 0.571rem 0.571rem;
  pointer-events: auto;
  border-radius: 0.3571rem;
}

.text-area.alternative-style {
  border-color: #ffffff;
  background-color: #ffffff;
}

.text-area:hover,
.text-area:focus-within {
  border-color: var(--primary-color);
}

.text-area.error,
.text-area.error:hover {
  border-color: var(--error-color);
}

.text-area.disabled,
.text-area.disabled:hover {
  border-color: var(--grey-palette-02);
  background-color: var(--grey-palette-03);
}

.text-area .native-input {
  flex-grow: 1;
  padding: 0;
  padding-left: 0.286rem;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}

.text-area.has-icon .native-input {
  padding-left: 0.429rem;
}

.text-area .native-input:focus {
  outline: none;
}

.text-area .native-input::placeholder {
  color: var(--placeholder-color);
}

.text-area.disabled .native-input {
  color: var(--grey-palette-01);
}

.text-area.disabled .native-input::placeholder {
  opacity: 0.4;
}

.text-area .text-area-icon {
  display: flex;
  align-items: center;
}

.text-area:hover .text-area-icon svg,
.text-area:focus-within .text-area-icon svg {
  fill: var(--primary-color);
}

.text-area.error .text-area-icon svg,
.text-area.error:hover .text-area-icon svg,
.text-area.error:focus-within .text-area-icon svg {
  fill: var(--error-color);
}
