.mobile-table-container {
  display: flex;
  flex-direction: column;
  gap: 1.4286rem;
  width: 100%;
  position: relative;
}

.mobile-table-container .mobile-table-rows-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.7143rem;
}

.mobile-table-container .mobile-table-empty-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
}
