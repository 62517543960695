.form-section {
  display: flex;
  flex-direction: column;
  gap: 0.8571rem;
}

.form-section > .section-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-row-gap: 1.1429rem;
  grid-column-gap: 4.2857rem;
}

.form-section > .section-inputs.input-gap {
  grid-column-gap: 1.1429rem;
}

.form-section .section-inputs > * {
  flex: 1;
}

.form-section .section-inputs.flex-unset > * {
  flex: unset;
}

.form-section .section-inputs.column {
  flex-direction: column;
}
