.add-info {
  display: flex;
  flex-direction: column;
  gap: 0.4286rem;
}

.add-info .add-info-header {
  display: flex;
  justify-content: space-between;
}

.add-info .add-info-content {
  display: flex;
  flex: 1;
  gap: 1.4286rem;
}

.add-info .add-info-content .add-info-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.add-info .add-info-content thead {
  color: var(--grey-palette-01);
}

.add-info .add-info-content thead .table-column-header {
  font-weight: var(--font-weight-bold);
  color: var(--grey-palette-01);
}

.add-info .add-info-content tbody .table-cell {
  font-size: 1rem;
  min-height: 2.5714rem;
  padding: 0.4286rem 0.857rem;
}
