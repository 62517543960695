.popup-reject-evaluation .reject-evaluation {
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
}

.popup-reject-evaluation .reject-evaluation .employee-card {
    padding: 1rem;
}

.popup-reject-evaluation .reject-evaluation .employee-card .employee-label {
    color: var(--grey-palette-01);
}

.popup-reject-evaluation .reject-evaluation .employee-card .employee-value {
    font-weight: var(--font-weight-bold);
}

.popup-reject-evaluation .popup-buttons .reject-btn{
    background-color: var(--status-red);
}