.personal-evaluation-page .page-content {
    display: flex;
    flex-direction: column;
    gap: 1.4286rem;
}

.personal-evaluation-page .top-section {
    display: flex;
    flex-direction: row;
    gap: 1.4286rem;
}

.personal-evaluation-page .top-section .period-data {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1.1429rem;
    height: auto;
}

.personal-evaluation-page .top-section .period-data .card-header {
    display: flex;
    justify-content: space-between;
}

.personal-evaluation-page .top-section .evaluation-status-card {
    max-width: 10rem;
}

.personal-evaluation-page .criterias-section {
    display: flex;
    flex-direction: row;
    gap: 1.4286rem;
}

.personal-evaluation-page .criterias-section .company-criteria, .personal-evaluation-page .criterias-section .employee-criteria {
    display: flex;
    flex-direction: column;
    gap: .5714rem;
    flex: 1;
    height: auto;
}

.personal-evaluation-page .criterias-section .employee-criteria{
    display: flex;
}

.personal-evaluation-page .criterias-section .table-row {
    pointer-events: none;
}

.personal-evaluation-page .evaluation-observations {
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
}