.popup-container.popup-add-cut-reason .popup-content.add-cut-reason .inputs-container {
    display: flex;
    flex-direction: column;
}

.popup-container.popup-add-cut-reason .popup-content.add-cut-reason .inputs-container .separator {
    font-weight: var(--font-weight-bold);
    color: var(--grey-palette-01);
    border-bottom: 1px solid rgba(185, 184, 184, 0.2);
    padding-bottom: 0.125rem;
    margin-top: 0.2857rem;
}

.popup-container.popup-add-cut-reason .popup-content.add-cut-reason .inputs-container .column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.popup-container.popup-add-cut-reason .popup-content.add-cut-reason .inputs-container .column-factors {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

