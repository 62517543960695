.mobile-nav {
  --mobile-navbar-height: 5rem;
}

.mobile-nav .mobile-navbar {
  width: 100%;
  min-height: var(--mobile-navbar-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-color);
  padding: 0 1.4286rem;

  -webkit-transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25s ease-in-out;
  -o-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
}

.mobile-nav .mobile-navbar.light-bg {
  background-color: var(--bg-main-color);
  color: var(--text-main-color);
  border-bottom: 0.0938rem solid var(--grey-palette-02);
}

.mobile-nav .mobile-navbar .mobile-navbar-logo-project {
  display: flex;
  align-items: center;
  gap: 1.1429rem;
}

.mobile-nav .mobile-navbar .mobile-navbar-logo-project .mobile-nav-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 8.5714rem;
}

.mobile-nav .mobile-navbar .mobile-navbar-logo-project .mobile-nav-logo svg {
  width: 100%;
}

.mobile-nav .mobile-navbar .mobile-navbar-logo-project .project {
  font-weight: var(--font-weight-bold);
  color: #ffffff;
  opacity: 0.2;
}

.mobile-nav .mobile-navbar.light-bg .mobile-navbar-logo-project .project {
  color: var(--text-main-color);
  opacity: 0.2;
}

.mobile-nav .mobile-nav-panel {
  position: absolute;
  top: var(--mobile-navbar-height);
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - var(--mobile-navbar-height));
  width: 100%;
  background-color: #ffffff;
  padding: 2rem 1rem;
}

/* .mobile-nav .mobile-nav-panel .mobile-nav-panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
} */

/* .mobile-nav .mobile-nav-panel .mobile-nav-panel-header .logo {
  display: flex;
  align-items: center;
  width: 8.75rem;
}

.mobile-nav .mobile-nav-panel .mobile-nav-panel-header .logo svg {
  width: 100%;
}

.mobile-nav .mobile-nav-panel .mobile-nav-panel-header .logo svg .st0 {
  fill: var(--main-gray);
}

.mobile-nav .mobile-nav-panel .mobile-nav-panel-header .close-btn {
  margin-left: 1.25rem;
} */

.mobile-nav .mobile-nav-panel .menu-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-nav .mobile-nav-panel .menu-content ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1.2857rem;
}

.mobile-nav .mobile-nav-panel .menu-content .account .account-menu-container {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.mobile-nav
  .mobile-nav-panel
  .menu-content
  .account
  .account-menu-container
  .account-info {
  align-items: baseline;
}

.mobile-nav .mobile-nav-panel .menu-content .account hr {
  margin: 1.4286rem 0;
  border: none;
  border-bottom: 0.0938rem solid var(--grey-palette-02);
}
