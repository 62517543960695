.popup-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-area {
  width: 90vw;
  height: 90vh;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-area > div:first-child {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 425px) {
  .popup-area {
    width: 100vw;
    height: 100vh;
    display: block;
  }
}
