.admin-content-container {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.admin-content-container .side-bar {
  display: flex;
  justify-content: space-between;
}
