.menu-table-button {
  width: 1.7143rem;
  height: 1.7143rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.menu-table-button .icon {
  width: 100%;
  height: auto;
}

.menu-table-button:hover {
  opacity: 0.7;
}

.menu-table-button.disabled {
  opacity: 0.3;
  pointer-events: none;
}
