.mini-dropdown {
  --select-single-padding: 0.571rem;
  display: flex;
  align-items: center;
  padding: 0 var(--select-single-padding);
  pointer-events: auto;
  position: relative;
  border-radius: 0.3571rem;
  background-color: var(--primary-color);
  position: relative;
  height: 1.7143rem;
}

.mini-dropdown .arrow-down-icon {
  cursor: pointer;
  min-width: 1.2857rem;
  height: 1.2857rem;
}

.mini-dropdown .arrow-down-icon path {
  fill: white;
}

.mini-dropdown.error,
.mini-dropdown.error:hover {
  border-color: var(--error-color);
}

.mini-dropdown.disabled,
.mini-dropdown.disabled:hover {
  border-color: var(--grey-palette-02);
  background-color: var(--grey-palette-03);
}

.mini-dropdown .native-input {
  flex-grow: 1;
  padding: 0;
  padding-left: 0.286rem;
  caret-color: var(--secondary-color);
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.mini-dropdown .native-input:focus {
  outline: none;
  color: var(--bg-main-color);
}

.mini-dropdown .native-input::placeholder {
  color: var(--bg-main-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.mini-dropdown.disabled .native-input::placeholder {
  color: var(--placeholder-disabled-color);
}

.mini-dropdown .mini-dropdown-options-panel {
  position: absolute;
  top: calc(100% + 0.2857rem);
  left: 0;
  min-width: 100%;
  z-index: 90;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #ffffff;
  color: var(--text-main-color);
  max-height: 11.6429rem;
  padding: 0.8571rem 0 0.4286rem 0;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  max-width: 15rem;
  border-radius: 0.3571rem;
}

.mini-dropdown .mini-dropdown-options-panel .mini-dropdown-option {
  min-height: 2.1429rem;
  padding: 0.357rem 0.857rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.mini-dropdown .mini-dropdown-options-panel .mini-dropdown-option.hover,
.mini-dropdown .mini-dropdown-options-panel .mini-dropdown-option:hover {
  background-color: var(--grey-palette-02);
  cursor: pointer;
}

.mini-dropdown .mini-dropdown-options-panel .mini-dropdown-option.selected {
  display: flex;
  justify-content: space-between;
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  background-color: var(--grey-palette-02);
}

.mini-dropdown
  .mini-dropdown-options-panel
  .mini-dropdown-option.selected
  .check-option
  path {
  fill: var(--primary-color);
}

.mini-dropdown
  .mini-dropdown-options-panel
  .mini-dropdown-option.selected
  .check-option
  path:first-child {
  fill: none;
}
