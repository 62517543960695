.popup-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1.1429rem;
}

.popup-buttons .button {
  flex: 1;
  max-width: 21.4286rem;
}
