.no-results {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
}

.no-results .no-results-title {
  margin: 1.1429rem 0 0.4286rem 0;
}

.no-results .no-results-subtitle {
  color: var(--grey-palette-01);
}
