.comment {
    display: flex;
    gap: .7143rem;
}

.comment .comment-avatar {
    width: 2.1429rem;
    height: 2.1429rem;
    font-size: 1rem;
    background: var(--secondary-color);
}

.comment .comment-info {
    display: flex;
    flex-direction: column;
    gap: .2143rem;
}

.comment .comment-info .commented-by {
    font-weight: var(--font-weight-bold);
    color: var(--grey-palette-01);
}

.comment .comment-info .comment-text {
    line-height: 1.2;
}