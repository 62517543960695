.period-details .page-content {
    display: flex;
    flex-direction: column;
    gap: 1.8571rem;
}

.period-details .page-content .period-data {
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
}

.period-details .page-content .period-data .card-header-container .header {
    display: flex;
    justify-content: space-between;
}

.period-details .page-content .period-data .card-header-container .sub-header-period-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.period-details .page-content .period-data .card-header-container .sub-header-period-data .created-date {
    color: var(--grey-palette-01);
    font-weight: var(--font-weight-bold);
}

.period-details .page-content .period-data .period-data-action-buttons {
    display: flex;
    justify-content: flex-end;
}

.period-details .page-content .criterias {
    display: flex;
    flex-direction: column;
    gap: 2.1429rem;
}

.period-details .page-content .warning-total-factors {
    display: flex;
    width: 100%;
/*     justify-content: center; */
}

.periods-content-container .page-layout.period-details .page-layout-header .page-layout-header-action-buttons .icon-button.primary svg path:first-child {
    fill: none;
}

.popup-container.system-popup.warning-popup.cancel-evaluation-period-popup .popup-buttons .button.primary {
    background: var(--status-red);
}

.popup-container.system-popup.warning-popup.activate-evaluation-period-popup .popup-buttons .button.primary {
    background: var(--status-blue);
}
