.popup-container {
  background-color: #ffffff;
  padding: 2.143rem;
  border-radius: 0.7143rem;
  min-width: 21.4286rem;
  width: 34.2857rem;
  max-height: 100%;
  overflow: auto;
}

.popup-container .loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 768px) {
  .popup-container {
    padding: 1.5714rem;
    width: unset;
  }
}

@media only screen and (max-width: 425px) {
  .popup-container {
    height: 100vh;
    width: 100vw;
    overflow: auto;
    border-radius: 0;
  }
}
