.page-layout.evaluation-period-managers {
    display: flex;
    flex-direction: column;
}

.page-layout.evaluation-period-managers .page-content .search-manager-box {
    width: 19.7857rem;
}

.page-layout.evaluation-period-managers .page-content .employee-info {
    display: flex;
    flex-direction: column;
    gap: .0714rem;
}

.page-layout.evaluation-period-managers .page-content .employee-info .employee-email {
    color: var(--grey-palette-01);
}

.page-layout.evaluation-period-managers .page-content .delegated-to-container{
    display: flex;
    flex-direction: column;
}

.page-layout.evaluation-period-managers .page-content .delegated-to{
    color: var(--grey-palette-01);
}

.remove-manager-popup .button.primary {
    background: var(--status-red);
}

.popup-container.system-popup.warning-popup.remove-delegation-popup .popup-buttons .button.primary {
    background: var(--status-red);
}

@media only screen and (max-width: 425px) {
    .page-layout.evaluation-period-managers .page-content .search-manager-box {
        width: 100%;
    }

    .page-layout.evaluation-period-managers .page-content .employee-info .employee-email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 190px;
    }
  }