.table-select-single {
  --select-single-padding: 0.571rem;
  display: flex;
  align-items: center;
  height: 2.857rem;
  min-width: min-content;
  position: relative;
  gap: 1rem;
}

.table-select-single .native-input {
  flex-grow: 1;
  padding: 0;
  padding-left: 0.286rem;
  caret-color: var(--secondary-color);
  color: var(--text-main-color);
}

.table-select-single.has-icon .native-input {
  padding-left: 0.429rem;
}

.table-select-single .table-select-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.429rem;
  padding: 0 0.286rem;
  --select-container-height: 1.7857rem;
  height: var(--select-container-height);
  border: 1px solid var(--grey-palette-01);
  border-radius: 0.2857rem;
}

.table-select-single .table-select-container:hover {
  cursor: pointer;
  background-color: #ffffff;
}

.table-select-single .table-select-container path {
  fill: var(--secondary-color);
}

.table-select-single .native-input:focus {
  outline: none;
}

.table-select-single .table-select-single-icon {
  display: flex;
  align-items: center;
}

.table-select-single .table-select-single-options-panel {
  position: absolute;
  top: var(--select-container-height);
  left: 0;
  min-width: 100%;
  z-index: 90;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #ffffff;
  color: var(--text-main-color);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0.3571rem;
}

.table-select-single .table-select-single-options-panel .single-select-actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 0.435rem 0.857rem;
  padding-bottom: 0;
}

.table-select-single .table-select-single-options-panel .single-select-option {
  padding: 0.357rem 0.857rem;
}

.table-select-single
  .table-select-single-options-panel
  .single-select-option.hover,
.table-select-single
  .table-select-single-options-panel
  .single-select-option:hover {
  background-color: var(--grey-palette-02);
  cursor: pointer;
}

.table-select-single
  .table-select-single-options-panel
  .single-select-option.selected {
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  background-color: var(--grey-palette-02);
}

@media only screen and (max-width: 768px) {
  .table-select-single-label {
    display: none;
  }
}
