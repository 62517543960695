.account-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8571rem;
  cursor: pointer;
}

.account-menu-container .account-info {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.account-menu-container .account-info .account-info-name {
  font-weight: var(--font-weight-bold);
}

.account-menu-container .account-info .account-info-email {
  color: var(--grey-palette-01);
  font-weight: var(--font-weight-bold);
  max-width: 17.8571rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.account-menu-container .account-info .avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8571rem;
  height: 2.8571rem;
  font-size: 1.1429rem;
  font-weight: var(--font-weight-bold);
  border-radius: 50%;
  background: var(--gradient-main);
}

.account-menu-container .account-info .avatar-container .avatar {
  display: flex;
  position: relative;
}

/* .account-menu-container .contextual-menu-panel {
  position: absolute;
  top: 66px;
  right: 4.8571rem;
  z-index: 90;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 5rem;
} */

.account-menu-container:hover .account-info .account-info-name,
.account-menu-container:hover .account-info .account-info-email,
.account-menu-container:hover .avatar-container {
  opacity: 0.7;
}
