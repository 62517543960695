.popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.popup-header h3 {
  margin-top: 0;
  margin-bottom: 0;
}

/* TEMP solution */
.popup-header .popup-close-icon {
  margin-right: -0.5rem;
}

.popup-header .title-and-subtitle {
  display: flex;
  flex-direction: column;
}

.popup-header .title-and-subtitle .popup-subtitle {
  color: var(--grey-palette-01);
  font-weight: var(--font-weight-bold);
}
