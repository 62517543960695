@keyframes blink {
  50% {
    color: transparent;
  }
}
.loader__dot {
  font-size: 0.8571rem;
  font-weight: var(--font-weight-bold);
  animation: 1s blink infinite;
}
.loader__dot:nth-child(2) {
  animation-delay: 250ms;
}
.loader__dot:nth-child(3) {
  animation-delay: 500ms;
}
