.employees-evaluations {
    gap: 1rem;
}

.employees-evaluations .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.employees-evaluations .page-content .text-input.search-box {
    width: 19.7857rem;
}