.info-tag {
    display: flex;
    gap: 0.2857rem;


}

.info-tag .info-tag-value{
    display: flex;
    align-items: center;
}


.info-tag .tooltip-host{
    display: flex;
    justify-content: center;
}  