.avatar-container {
    position: relative;
    cursor: pointer;
    max-width: 2.8571rem;
}

.avatar-container .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.8571rem;
    height: 2.8571rem;
    background: var(--gradient-main);
    font-size: 1.1429rem;
    font-weight: var(--font-weight-bold);
    border-radius: 50%;
    cursor: pointer;
    color: var(--bg-main-color);
}


.avatar-container .avatar-state {
    position: absolute;
    width: .8571rem;
    height: .8571rem;
    border-radius: 50%;
    bottom: 0;
    right: 0;
}

.avatar-container .avatar-state.primary {
    border: 1px solid var(--bg-main-color);
    background-color: var(--success-color);
}

.avatar-container .avatar-state.secondary {
    border: 1px solid var(--bg-main-color);
    background-color: var(--error-color);
}

.avatar-container .avatar-state.tertiary {
    border: 1px solid var(--bg-main-color);
    background-color: var(--grey-palette-01);
}

.avatar-container .avatar-state.quaternary {
    border: 1px solid var(--bg-main-color);
    background-color: var(--warning-color);
}