.popup-register-feedback .popup-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.popup-register-feedback .employee-card {
    padding: 1rem;
}

.popup-register-feedback .employee-card .employee-label {
    color: var(--grey-palette-01);
}

.popup-register-feedback .employee-card .employee-value {
    font-weight: var(--font-weight-bold);
}

.popup-register-feedback .subtitle-period-closed{
    font-weight: var(--font-weight-bold);
}

.popup-register-feedback .feedback-given-period-closed{
    font-style: italic;
}


.popup-register-feedback .feedback-info {
    padding-top: .3571rem;
    display: flex;
    flex-direction: column;
    gap: .2143rem;
}

.popup-register-feedback .feedback-info .feedback-subtitle {
    font-weight: var(--font-weight-bold);
    color: var(--grey-palette-01);
}

.popup-register-feedback .feedback-info  .feedback-text {
    line-height: 1.2;
}

.popup-register-feedback .comments {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
    gap: 1.1429rem;
}