@import "colors.css";

@import url("./fonts/roboto/roboto.css");

:root {
  --font-family: "Roboto";

  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;
}

h1,
h2,
h3,
.title,
.subtitle,
body,
.small-copy,
caption,
.caption,
.tiny-copy,
input,
textarea {
  color: var(--text-main-color);
  font-family: var(--font-family), -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

h1 {
  font-size: 3rem;
  font-weight: var(--font-weight-bold);
}

h2 {
  font-size: 2.4286rem;
  font-weight: var(--font-weight-bold);
}

h3 {
  font-size: 1.8571rem;
  font-weight: var(--font-weight-bold);
}

.title {
  font-size: 1.5714rem;
  font-weight: var(--font-weight-bold);
}

.subtitle {
  font-size: 1.2857rem;
  font-weight: var(--font-weight-medium);
}

html,
body {
  font-size: 14px;
  font-weight: var(--font-weight-regular);
}

.small-copy {
  font-size: 0.8571rem;
  font-weight: var(--font-weight-regular);
}

caption,
.caption {
  font-size: 0.7143rem;
  font-weight: var(--font-weight-regular);
}

.tiny-copy {
  font-size: 0.5714rem;
  font-weight: var(--font-weight-regular);
}

input,
textarea {
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
  caret-color: var(--text-main-color);
}

.bold {
  font-weight: var(--font-weight-bold);
}
