.evaluations-page .page-content {
    display: flex;
    flex-direction: column;
    gap: 1.4286rem;
}

.evaluations-page .page-content .evaluations-page-header {
    display: flex;
    flex-direction: row;
    gap: 1.4286rem;
}

.evaluations-page .page-content .period-data {
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
    flex: 1;
    height: 100%;
}

.evaluations-page .page-content .period-data .card-header {
    display: flex;
    justify-content: space-between;
}

.evaluations-page .page-content .add-info.add-info-attachments {
    display: flex;
    max-width: 50%;
}

.evaluations-page .page-content .add-info.add-info-attachments .card-container {
    flex: 1;
}

.popup-container.system-popup.warning-popup.revert-evaluation-popup .popup-buttons .button.primary {
    background: var(--warning-color);
}


.evaluations-page .page-content .filters-container {
    display: flex;
    justify-content: space-between;
    gap: 1.1429rem;
}

.evaluations-page .page-content .filters-container .text-input.search-box {
    width: 19.7857rem;
}

.evaluations-page .page-content .filters-container .checkbox-info {
    display: flex;
    gap: 1.1429rem;
}


.evaluations-page .period-not-active-message {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.evaluations-page .period-data .card-separator-line {
    font-weight: var(--font-weight-bold);
    color: var(--grey-palette-01);
    border-bottom: 1px solid rgba(185, 184, 184, 0.2);
}

.evaluations-page .period-data .performance-indicators {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.evaluations-page .period-data .performance-indicators .label {
    font-weight: 600;
}

.evaluations-page .period-data .performance-indicators .container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}



 .evaluations-page .sublime-line {
    font-weight: var(--font-weight-bold);
    color: var(--grey-palette-01);
    border-bottom: 1px solid rgba(185, 184, 184, 0.2);
} 






@media only screen and (max-width: 1024px) {
    .evaluations-page .page-content .evaluations-page-header {
        flex-direction: column;
    }

    .evaluations-page .page-content .add-info.add-info-attachments {
        max-width: 100%;
    }
}


@media only screen and (max-width: 768px) {
    .evaluations-page .filters-container {
        justify-content: unset;
        flex-wrap: wrap;
    }

    .evaluations-page .filters-container .checkbox-info {
        display: flex;
        gap: 1.1429rem;
    }
}

@media only screen and (max-width: 425px) {

    .evaluations-page .filters-container .form-field,
    .evaluations-page .filters-container .text-input.search-box {
        width: 100%;
    }
}