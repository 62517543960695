.card-container {
  height: fit-content;
  background-color: var(--grey-palette-02);
  padding: 1.5714rem 1.7143rem 2.1429rem 1.7143rem;
  border-radius: 0.7143rem;
}


.card-container.titled.loading.small {
  min-height: 8.9286rem;
}

.card-container.titled.loading.medium {
  min-height: 15.3571rem;
}

.card-container.titled.loading.large {
  min-height: 22.8571rem;
}

@media only screen and (max-width: 425px) {
  .card-container {
    padding: 1rem 1rem 1.7143rem 1rem;
  }
}