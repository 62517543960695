.popup-employee-hour-records {
    min-width: 45vw
}

.popup-employee-hour-records .card-container {
    display: flex;
    gap: 3rem
}

.popup-employee-hour-records .table-cell.table-row-cell .warning-icon {
    max-height: 30px;
    max-width: 30px
}