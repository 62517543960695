.page-layout.hours-and-absence-page .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.page-layout.hours-and-absence-page .page-content .text-input.search-box {
    width: 19.7857rem;
}

.page-layout.hours-and-absence-page .page-content .table-container .absenteeism {
  font-weight: var(--font-weight-regular);
}

@media only screen and (max-width: 425px) {
  .page-layout.hours-and-absence-page .page-content .text-input.search-box {
    width: 100%;
}
}