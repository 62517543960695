.salaries-page .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.salaries-page .page-content .text-input.search-box {
    width: 19.7857rem;
}

@media only screen and (max-width: 425px) {
    .salaries-page .page-content .text-input.search-box {
        width: 100%;
    }
  }