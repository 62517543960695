.date-picker .date-picker-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid var(--border-main-color);
  border-radius: 0.3571rem;
  height: 2.857rem;
  padding: 0 0.571rem;
  margin: 0;
  pointer-events: auto;

  -webkit-transition: border 0.25s ease-in-out;
  -moz-transition: border 0.25s ease-in-out;
  -o-transition: border 0.25s ease-in-out;
  transition: border 0.25s ease-in-out;
}

.date-picker.alternative-style .date-picker-input {
  border-color: #ffffff;
}

.date-picker.disabled .date-picker-input input::placeholder {
  color: var(--placeholder-disabled-color);
}

.date-picker.disabled .date-picker-input path {
 opacity: .3;
}

.date-picker .date-picker-input input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  cursor: pointer;
  padding-right: 0.625rem;
}

.date-picker .date-picker-input svg {
  cursor: pointer;
  min-width: 1.5714rem;
  height: auto;
}

.date-picker .date-picker-input input:disabled {
  background-color: transparent;
  pointer-events: none;
  color: var(--placeholder-disabled-color);
}

.date-picker.disabled .date-picker-input svg {
  cursor: unset;
}

.date-picker .date-picker-input input::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

.date-picker .date-picker-input input::-ms-input-placeholder {
  color: var(--placeholder-color);
}

.date-picker .date-picker-input:hover,
.date-picker .date-picker-input:focus {
  outline: none;
  border: 1px solid var(--primary-color);
}

.date-picker.disabled .date-picker-input:hover,
.date-picker.disabled .date-picker-input {
  border-color: var(--grey-palette-02);
  background-color: var(--grey-palette-03);
}

.date-picker .date-picker-input input:focus {
  outline: none;
}
