.popup-container.popup-delete-evaluation {
    padding: 0;
}

.popup-container.popup-delete-evaluation .wrapper {
    background-color: var(--status-red);
    padding: 2.143rem 2.143rem 0 2.143rem;
}

.popup-delete-evaluation .delete-evaluation {
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
}


.popup-delete-evaluation .popup-header .title-and-subtitle h3 {
    color: white
}

.popup-delete-evaluation .popup-header .popup-close-icon svg path:last-child {
    fill: white;
}


.popup-delete-evaluation .popup-icon {
    display: flex;
    justify-content: center;
    height: 7.1429rem;
    margin-bottom: .2857rem;

}

.popup-delete-evaluation .popup-icon svg {
    width: auto;
    height: 100%;
}

.popup-delete-evaluation .popup-icon svg path {
    fill: white;
}


.popup-delete-evaluation .alert-message {
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
    margin-bottom: 1.1429rem;
    font-weight: bold;
    font-size: 1.1429rem;
}

.popup-delete-evaluation .card-container {
    background-color: white;
}

.popup-delete-evaluation .delete-evaluation .employee-card {
    padding: 1rem;
}

.popup-delete-evaluation .delete-evaluation .employee-card .employee-label {
    color: var(--grey-palette-01);
}

.popup-delete-evaluation .delete-evaluation .employee-card .employee-value {
    font-weight: var(--font-weight-bold);
}

.popup-delete-evaluation .popup-buttons {
    padding: 2.143rem;
}


.popup-delete-evaluation .popup-buttons .delete-evaluation-btn {
    background-color: var(--status-red);
}